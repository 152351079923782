<template>
    <div id="footer">
        <p>
            <router-link to="/about">Sobre nós</router-link> | 
            <router-link to="/contact">Contato</router-link> |
            <router-link to="/privacy">Política de privacidade</router-link>
        </p>
        <p>&copy; 2024 - Todos os direitos reservados - Master Clínica  - 
            CNPJ xx.xxx.xxx/xxxx-xx - Cidade - UF
        </p>
    </div>
    
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>
    #footer{
        font-size: 0.8em;
        font-weight: 400;
        background-color: var(--preto);
        padding: 4px;
        color: var(--cinza-claro);
        display: flex;
        flex-direction: column;
        align-items:center;
    }

    a {
        text-decoration: none;
        color: var(--verde);
    }
    a:hover{
        color: var(--cinza-claro);
    }

</style>