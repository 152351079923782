<template>
  <div class="home">
    <h1>home</h1>
  </div>
</template>

<script>


</script>
