<template>
    <div id="nav">
        <router-link to="/">
            <img id="logo" alt="Master clínica logo" src="../assets/logo_master.png">
        </router-link>
        <ul class="nav_links">
            <li><router-link to="/about">Sobre nós</router-link></li>
            <li><router-link to="/consult">Consultar</router-link></li>
            <li><router-link to="/accredited">Rede credenciada</router-link></li>
            <li><router-link to="/blog">Blog</router-link></li>
            
        </ul>
        <router-link class="login_btn" to="/login">Entrar</router-link>
    <img class="toggle_menu" src="../assets/menu.png" alt="Menu">
    
    <div class="dropdown_menu">
        <li><router-link to="/about">Sobre nós</router-link></li>
        <li><router-link to="/consult">Consultar</router-link></li>
        <li><router-link to="/accredited">Rede credenciada</router-link></li>
        <li><router-link to="/blog">Blog</router-link></li>
        <li><router-link class="login_btn" to="/login">Entrar</router-link></li>
    </div>

  </div>
</template>

<script>
    export default {
        name: "Navbar"
    }
</script>

<style scoped>
    #nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
    }

    li {
        list-style: none;
        margin: 0 10px;
    }

    li a {
        position: relative;
        font-size: 1em;
        font-weight: 700;
        padding: 4px 0;
        color: var(--verde);
        text-decoration: none;
    }

    .nav_links {
        display: flex;
        padding: 20px 15px;
    }

    .nav_links li a:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: var(--verde);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 400ms ease;
    }

    .nav_links li a:hover:before {
        transform: scaleX(1);
        transform-origin: left;
    }

    .login_btn {
        text-decoration: none;
        background-color: var(--verde);
        color: var(--branco);
        padding: 0.5rem 1rem;
        border: none;
        outline: none;
        border-radius: 15px;
        font-size: 1rem;
        font-weight: 600;
        cursor: pointer;
    }

    .login_btn:hover {
        box-shadow: 0 0 2px var(--preto);
        color: var(--cinza-claro);
    }
    #nav .toggle_menu {
        height: 48px;
        width: 48px;
        cursor: pointer;
        display: none;
    }

    .dropdown_menu {
        display: none;
        position: absolute;
        right: 2rem;
        top: 50px;
        background-color: #0000000F;
        border-radius: 10px;
        overflow: hidden;
    }

    .dropdown_menu li {
        padding: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dropdown_menu .login_btn {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    @media (max-width: 850px) {
        .nav_links, .login_btn {
            display: none;
        }

        #nav .toggle_menu {
            display: block;
        }
    }
</style>